/**
 * A RovoLogo is a subset API that works for both @atlaskit/logo and @atlaskit/icon
 * We use one or the other depending on 1P or 3P and need a consistent API internall
 */
import React from 'react';

import Icon, { type Size as IconSize } from '@atlaskit/icon';
import { fg } from '@atlaskit/platform-feature-flags';

import { SVGIcon } from '../../ui/icon';

type Size = 'small' | 'medium' | 'large';

export type RovoLogoGlyphWrapper = (
	glyph: () => JSX.Element,
	defaultLabel?: string,
	defaultSize?: Size,
) => RovoLogo;
export type RovoLogoLogoWrapper = (LogoComponent: any, defaultLabel?: string) => RovoLogo;

export type RovoLogoProps = {
	size?: Size;
	label?: string;
	testId?: string;
};

export type RovoLogo = (props: RovoLogoProps) => JSX.Element;

/**
 * Due a bug in ADS custom icons are one size smaller (than atlaskit/logo)
 * To accommodate for this behavior you need to use one size bigger than what you need
 * See: https://atlassian.slack.com/archives/CFJ9DU39U/p1714096323217799
 */
const correctedSize: Record<Size, IconSize> = {
	small: 'medium',
	medium: 'large',
	large: 'xlarge',
};

/**
 * A wrapper for providing an internal \@atlaskit/icon - alike experience for working with custom Rovo Icons
 *
 * Internally uses <Icon glyph={glyph} ... />
 *
 * @param {} glyph function returning an SVG as JSX
 * @param defaultLabel an accessible label for the logo
 */
export const RovoLogoFromGlyph: RovoLogoGlyphWrapper = (
	glyph,
	defaultLabel = '',
	defaultSize?: Size,
) => {
	return ({ size = defaultSize, label = defaultLabel, testId }) => {
		return (
			<Icon
				label={label}
				testId={testId}
				glyph={glyph}
				size={size ? correctedSize[size] : defaultSize}
			/>
		);
	};
};

/**
 * A wrapper for rendering 3P svg illustrations in a similar way for atlaskit icon components
 *
 * @param {} glyph function returning an SVG as JSX
 * @param defaultLabel an accessible label for the logo
 */
export const RovoLogoFromSVG: RovoLogoGlyphWrapper = (
	glyph,
	defaultLabel = '',
	defaultSize?: Size,
) => {
	return ({ label = defaultLabel, size = defaultSize, testId }) => (
		<SVGIcon label={label} glyph={glyph} size={size ? correctedSize[size] : 'medium'} />
	);
};

/**
 * A wrapper for providing an internal \@atlaskit/icon - alike experience for working with \@atlaskit/logo
 *
 * Internally uses <LogoComponent ... />
 *
 * @param {} LogoComponent an import from \@atlaskit/logo
 * @param defaultLabel an accessible label for the logo
 */
export const RovoLogoFromLogo: RovoLogoLogoWrapper = (LogoComponent, defaultLabel = '') => {
	return ({ size, label, testId }) => {
		return (
			<LogoComponent
				label={label || defaultLabel}
				testId={testId}
				size={size || 'medium'}
				appearance="brand"
			/>
		);
	};
};

export function get3PLogo(iconGlyph: () => JSX.Element, iconLabel: string, size?: Size): RovoLogo {
	return fg('rovo_search_custom_icon_component')
		? RovoLogoFromSVG(iconGlyph, iconLabel, size)
		: RovoLogoFromGlyph(iconGlyph, iconLabel);
}
